import React from 'react';
import styled from 'styled-components';
import DefaultLayout from '../layouts/DefaultLayout';
import SecondaryCallout from '../components/SecondaryCallout';
import { H3, Reference } from '../util/typography';
import PageIntroduction from '../components/PageIntroduction';
import { phoneIconGreen } from '../util/icons';
import Subsection from '../components/Subsection';
import FeaturedResource from '../components/FeaturedResource';
import MerckAccessProgramImage from '../assets/patient-support-icons/patient-support-merk-access-program.png';
import keyYouLogo from '../assets/patient-support-icons/key-you-logo.png';
import doseLogo from '../assets/patient-support-icons/patient-support-dose-exchange.png';
import copayLogo from '../assets/patient-support-icons/patient-support-copay.png';
import accessLogo from '../assets/patient-support-icons/patient-support-accessing-lenvima.png';
import eisaiSupport from '../assets/patient-support-icons/patient-support-eisai.png';
import { respondTo } from '../util/breakpoints';
import Section from '../components/Section';
import * as colors from '../util/colors';
import { jobCodes } from '../util/data/jobCodes';

// SEO INFORMATION
const pageMeta = {
    title: `KEYTRUDA® (pembrolizumab) and LENVIMA® (lenvatinib) | Patient Support`,
    keywords: `keytruda and lenvima financial assistance`,
    description: `Health care professionals can learn about support resources for patients prescribed KEYTRUDA® (pembrolizumab) and LENVIMA® (lenvatinib).`,
    schemaJsonLD: [
        `{"@context":"https://schema.org/","url":"https://www.keytrudalenvimahcp.com/patient-support/","@type":"MedicalAudience","audienceType":"Health Care Professionals","geographicArea":"This site is intended for health care professionals of the United States, its territories, and Puerto Rico."},`,
        `{"@context":"https://schema.org/","url":"https://www.keytrudalenvimahcp.com/patient-support/","@type":"MedicalWebPage","name":"Dose Exchange Program ","description":"For assistance with patients that require a dose reduction, please visit www.LENVIMAREIMBURSEMENT.com/hcp. "}`,
        `{"@context":"https://schema.org/","url":"https://www.keytrudalenvimahcp.com/patient-support/","@type":"MedicalWebPage","name":"LENVIMA Co-Pay Program ","description":"With the LENVIMA Co-Pay Program, eligible commercially insured patients may pay as little as $0 per month.* Annual limits apply. Depending on the insurance plan, patients could have additional financial responsibility. See www.LENVIMAREIMBURSEMENT.com/hcp for complete terms and conditions."}`,
        `{"@context":"https://schema.org/","url":"https://www.keytrudalenvimahcp.com/patient-support/","@type":"MedicalWebPage","name":"Merck Access and Support for KEYTRUDA ","description":"The Merck Access Program can help answer questions about benefit investigations, billing and coding, co-pay assistance for eligible patients, prior authorization process, and more."}`,
        `{"@context":"https://schema.org/","url":"https://www.keytrudalenvimahcp.com/patient-support/","@type":"MedicalWebPage","name":"Information and support resources for patients who are prescribed KEYTRUDA® (pembrolizumab) + LENVIMA® (lenvatinib)","description":"For questions about access or support, reach out to a Call Center Agent at 833‑866‑0789."}`,
        `{"@context":"https://schema.org/","url":"https://www.keytrudalenvimahcp.com/patient-support/","@type":"MedicalWebPage","name":"Eisai Patient Support","description":"Eisai Patient Support offers information and resources to help patients get access to LENVIMA, including: Benefit verification, co-pay assistance, the LENVIMA Welcome Kit, the LENVIMA Dose Exchange Program, and the LENVIMA Patient Assistance Program."}`,
        `{"@context":"https://schema.org/","url":"https://www.keytrudalenvimahcp.com/patient-support/","@type":"MedicalWebPage","name":"Accessing LENVIMA ","description":"Certain physician practices with dispensing capabilities and certain hospital pharmacies can purchase WELIREG from these Specialty Distributors"}`
    ]
}
const jobCode = jobCodes[0].jobCode;

const PSSubsection = styled(Subsection)`
    ${respondTo.md`
        &:first-child{
            padding-top: 40px;
        }
    `}
`;

const PSSection = styled(Section)`
        padding-bottom: 60px;
`;

const BreakForSmallerDevices = styled.span`
    text-wrap: wrap;
    white-space: wrap;
    @media (min-width: 360px) {
        text-wrap: nowrap;
        white-space: nowrap;
    }
`;



const LenvimaLink = styled.a`
    font-weight: 600;
    color: ${colors.mulberry};
    &:hover {
        color: ${colors.mulberryHover};
    }
`;

const KeytrudaLink = styled.a`
    font-weight: 600;
    color: ${colors.greenDark};
    &:hover {
        color: ${colors.greenRollOver};
    }
`;


const PatientSupport = ({location}) => {
    return (
        <DefaultLayout location={ location } name='Patient Support' pageMeta={pageMeta} jobCode={jobCode}>
            <PageIntroduction>
                <H3>Information and support resources for patients who are prescribed <BreakForSmallerDevices><keytitle>KEYTRUDA®&nbsp;(pembrolizumab)</keytitle></BreakForSmallerDevices> <b>+</b>  <lentitle>LENVIMA®&nbsp;(lenvatinib)</lentitle></H3>
            </PageIntroduction>
            <SecondaryCallout icon={phoneIconGreen}>
                <p>For questions about access or support, reach out to a Call Center Agent at <KeytrudaLink href='tel:833-866-0789'>833&#8209;866&#8209;0789</KeytrudaLink>.</p>
            </SecondaryCallout>
            <PSSection noIntro>
                <PSSubsection>
                    <FeaturedResource buttonText='Visit The Merck Access Program now' buttonTo='https://www.merckaccessprogram-keytruda.com/' imgSrc={MerckAccessProgramImage} imgAlt=''>
                        <p><keytitle>Merck Access and Support for KEYTRUDA</keytitle></p>
                        <p><strong>The Merck Access Program</strong> can help answer questions about benefit investigations, billing and coding, co-pay assistance for eligible patients, prior authorization process, and more.</p>
                    </FeaturedResource>
                </PSSubsection>
                <PSSubsection>
                    <FeaturedResource imgSrc={keyYouLogo} noTitle imgAlt='KEY+YOU Logo'>
                        <div>
                            <p>The <keytitle><b>KEY+YOU Patient Support Program</b></keytitle> is a program for eligible patients <b>who are prescribed KEYTRUDA</b> to find educational resources that may help address their questions, connect them to helpful organizations, or provide help with the day-to-day challenges they may be facing. From preparing for treatment to dealing with emotions, these resources are available for them.</p><br/>
                            <p>Eligible patients and caregivers can learn more about the KEY+YOU Patient Support Program by calling <KeytrudaLink className='keytruda' href='tel:855-398-7832'>85-KEYTRUDA (855&#8209;398&#8209;7832)</KeytrudaLink> and <b>pressing 2</b> to speak to a representative.</p><br/>
                            <p>KEY+YOU is for patients who have been prescribed KEYTRUDA for an approved indication, and their caregivers. KEY+YOU provides general information about KEYTRUDA and does not take the place of a patient’s health care team. All questions that are specific to a patient’s medical condition, treatment, or side effects should be directed to the patient’s health care team right away.</p><br/>
                            <p>KEY+YOU is sponsored by Merck, the marketer of KEYTRUDA.</p>
                        </div>
                    </FeaturedResource>
                </PSSubsection>
                <PSSubsection>
                    <FeaturedResource imgSrc={eisaiSupport} imgAlt='Eisai Patient Support Logo'>
                        <p><lentitle>Eisai Patient Support</lentitle></p>
                        <div>
                            <p>Eisai Patient Support offers information and resources to help patients get access to LENVIMA, including: Benefit verification, co-pay assistance, the LENVIMA Welcome Kit, the LENVIMA Dose Exchange Program, and the LENVIMA Patient Assistance Program.</p><br/>
                            <p>Eligibility criteria apply. For program and eligibility information, please visit <LenvimaLink className='lenvima' href='https://www.eisaireimbursement.com/hcp/lenvima/patient-support' target='_blank'>www.LENVIMAREIMBURSEMENT.com/hcp</LenvimaLink> or call <LenvimaLink className='lenvima' href='tel:1-866-613-4724'>1&#8209;866&#8209;613&#8209;4724</LenvimaLink>.</p><br/>
                            <p>Eisai cannot guarantee payment of any claim. Coding, coverage, and reimbursement may vary significantly by payor, plan, patient, and setting of care. Actual coverage and reimbursement decisions are made by individual payors following the receipt of claims. For additional information, customers should consult with their payors for all relevant coding, reimbursement, and coverage requirements. It is the sole responsibility of the provider to select the proper code and ensure the accuracy of all claims used in seeking reimbursement. All services must be medically appropriate and properly supported in the patient medical record.</p>
                        </div>
                    </FeaturedResource>
                </PSSubsection>
                <PSSubsection>
                    <FeaturedResource imgSrc={doseLogo} imgAlt=''>
                        <p><lentitle>Dose Exchange Program</lentitle></p>
                        <div>
                            <p>For assistance with patients that require a dose reduction, please visit <LenvimaLink className='lenvima' href="https://www.eisaireimbursement.com/hcp/lenvima/patient-support" target='_blank'>www.LENVIMAREIMBURSEMENT.com/hcp</LenvimaLink>. </p><br/>
                        </div>
                    </FeaturedResource>
                </PSSubsection>
                <PSSubsection>
                                <FeaturedResource imgSrc={copayLogo} imgAlt=''>
                                    <p><lentitle>LENVIMA Co-Pay Program</lentitle></p>
                                    <div>
                                        <p>
                                            With the LENVIMA Co-Pay Program, eligible commercially insured patients may pay as little as $0 per month.* Annual limits apply. Depending on the insurance plan, patients could have additional financial responsibility. See <LenvimaLink className='lenvima' href="http://www.LENVIMAREIMBURSEMENT.com/hcp" target='_blank'>www.LENVIMAREIMBURSEMENT.com/hcp</LenvimaLink>  for complete terms and conditions. 
                                        </p><br/>
                                        <p>
                                          For assistance, call <LenvimaLink className='lenvima' href="tel:+18553472448">1&#8209;855&#8209;347&#8209;2448</LenvimaLink> or visit <LenvimaLink className='lenvima' href="http://www.lenvimacopay.com" target='_blank'>www.LENVIMACopay.com</LenvimaLink> to enroll eligible patients.
                                        </p><br/>
                                        <Reference>
                                            *Not available to patients enrolled in state or federal healthcare programs, including Medicare, Medigap, VA, DoD, or TRICARE.
                                        </Reference>
                                    </div>
                                </FeaturedResource>
                      </PSSubsection>
                      <PSSubsection>
                              <FeaturedResource imgSrc={accessLogo} imgAlt=''>
                                    <p><lentitle>Accessing LENVIMA</lentitle></p>
                                    <div>
                                        <b>Specialty Pharmacies</b><br/><br/>
                                        <p>
                                            LENVIMA is available through certain Specialty Pharmacies, which will mail the medication directly to patients.
                                        </p><br/>
                                        <p>
                                            Please visit <LenvimaLink className='lenvima' href="https://www.eisaireimbursement.com/patient/lenvima" target='_blank'>www.LENVIMASpecialtyPharmacy.com</LenvimaLink>  for a complete list of the mail-order Specialty Pharmacies that dispense LENVIMA.
                                        </p>
                                    </div>
                                    <div>
                                        <b>
                                            Physician Office/Clinic or Hospital Pharmacies
                                        </b><br/><br/>
                                        <p>
                                            LENVIMA can also be dispensed through eligible physician offices, clinics, or hospital pharmacies. Please contact your preferred distributor for more information, including eligibility requirements.
                                        </p>
                                    </div>
                                </FeaturedResource>
                      </PSSubsection>
            </PSSection>
        </DefaultLayout>
    )
};

export default PatientSupport;
